import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCommonService } from '../../../../core/services/api/api-common.service';
import { IRoomDTOnew } from '../../model/room.model';

@Injectable({
  providedIn: 'root',
})
export class RoomsApiService extends ApiCommonService {
  getRoomsByApartmentId = (
    apartmentId: number
  ): Observable<IRoomDTOnew[] | null> =>
    this.httpGet<IRoomDTOnew[]>('/room/by-apartment-id', {
      params: {
        apartmentId: apartmentId,
      },
    });

  getRoomsByIds = (
    ids: number[],
    cacheTime?: number
  ): Observable<IRoomDTOnew[] | null> =>
    this.getCached(
      () =>
        this.httpGet<IRoomDTOnew[]>('/room', {
          params: { ids },
        }),
      'room',
      cacheTime
    );

  deleteRoom = (ids: number[]): Observable<IRoomDTOnew[] | null> =>
    this.httpDelete<IRoomDTOnew[]>('/room', {
      params: { ids },
    });
}
